import styled from 'styled-components'
import theme from '../../utils/theme'

export const Headers = styled.header`
  background-color: #FFCF7A;
  padding: 0.4rem 2rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a{
    color: #323232 !important;
  };
  h1{
    padding: 1vh;
    font-size: 2em;
    color: #323232;
    @media only screen and (max-width: 600px) {
      font-size: 1em;
    }
  };
`

export const Layouts = styled.div`
  margin: 0 auto;
  max-width: 960;
  padding-top: 0;
  main{
    padding: 0 2rem 2rem;
  }
  @media only screen and (max-width: 766px) {
    main {
      padding: 0 0.5rem 0.5rem;
    }
  }
`

export const Footer = styled.footer`
  background-color: #FFCF7A;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  align-items: center;
  padding: 0 2rem;
`