import React from 'react'
import { Link } from 'gatsby'
import { logout } from '../services/auth'
import { connect } from 'react-redux'
import { Headers } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { navigate } from 'gatsby';
import BGMSound from '../assets/Audio/bgmusic.wav';

let BGMAudio;

if (typeof window !== 'undefined') {
  BGMAudio = new Audio(BGMSound);
}

const Header = ({ auth }) => {
  const handleLogout = () => {
    logout()
    navigate('/')
  }

  const toggleBGM = () => {
    if (BGMAudio.paused) {
      BGMAudio.play();
      BGMAudio.ontimeupdate = function(i) {
        if((this.currentTime / this.duration)>0.98){
          this.currentTime = 0;
          this.play();
        }
      };
    } else {
      BGMAudio.pause();
    }
  }

  return (
  <Headers>
    <>
      <h1 style={{ margin: 0 }}>
        <Link
          to='/'
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          Lomito City
        </Link>
      </h1> 
    </>
      {
        auth && 
        <div>
          <span onClick={toggleBGM}>🎵</span>
        </div>
      }
    <nav >

      {!auth && <Link
        style={{color: 'white'}} 
        to='/app/play'>Jugar
      </Link>}
      {auth && <>
        <Link style={{color: 'white'}}   to='/app/menu'>Menu <FontAwesomeIcon icon={faUserCircle}/></Link>
        &nbsp;
        <a style={{color: 'white'}} onClick={handleLogout}>Salir <FontAwesomeIcon icon={faSignOutAlt}/></a>
      </>}
    </nav>
  </Headers>
)}

export default connect(state => ({auth: state.auth}))(Header)
