
import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

import Header from "./header"
import { Provider } from 'react-redux'
import store from '../store'

import { Layouts, Footer } from './styles/index'

const Layout = ({ children }) => { 
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return(
    <Provider store={store}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Layouts >
        <main>{children}</main>
        <Footer/>
      </Layouts>
    </Provider>
  )  
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
